import { IBadgeFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import Badge, { BadgeProps } from './Badge'

const ContentfulBadge = withContentful<IBadgeFields, BadgeProps>(Badge, {
  purpose: (props) =>
    props.fields.purpose !== 'Default'
      ? (props.fields.purpose.toLowerCase() as Lowercase<typeof props.fields.purpose>)
      : undefined,
})
export default ContentfulBadge
