import { Badge as UDSBadge } from '@telus-uds/components-web'
import { SpacerProps } from '../Spacer/Spacer'
import { TextRenderer } from '../../../utils/text/TextRenderer'

type purposeOptions = 'editorial' | 'offer'

export type BadgeProps = {
  text: string
  purpose?: purposeOptions
  outline?: boolean
  alternative?: boolean
  spacer?: SpacerProps
}

const Badge = ({ text, purpose, outline, alternative }: BadgeProps) => (
  <UDSBadge
    testID="badge-testID"
    variant={{
      purpose,
      outline,
      alternative,
    }}
  >
    <TextRenderer>{text}</TextRenderer>
  </UDSBadge>
)

export default Badge
